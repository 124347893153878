<template>
  <div>
    <div
      v-permission="'institutions.create'"
      class="flex flex-wrap-reverse items-center"
    >
      <div
        class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary"
        @click="addNew"
      >
        <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
        <span class="ml-2 text-base text-primary">{{ $t('AddNew') }}</span>
      </div>
    </div>
    <Grid
      :service="service" route_name="institutions"
      :column_formats="{
        'organization_id' : (data, rowData) => _.get(rowData, 'organization.name', '') || ''
      }"
    >
      <template v-slot:gridThead>
        <vs-th key="id" sort-key="id">ID</vs-th>
        <vs-th key="name" sort-key="name">{{ $t('name') }}</vs-th>
        <vs-th key="internal_name" sort-key="internal_name">{{ $t('internal_name') }}</vs-th>
        <vs-th key="organization_id" :sort-key="null"
          >{{ $t('organization') }}</vs-th
        >
      </template>
    </Grid>
  </div>
</template>

<script>
import InstitutionService from '@/services/api/InstitutionService'

export default {
  data: () => ({
    selected: [],
    service: null
  }),
  methods: {
    addNew() {
      this.$router.push('/institutions/create')
    }
  },
  beforeMount() {
    this.service = InstitutionService.build(this.$vs)
  }
}
</script>
